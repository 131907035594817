<script setup lang="ts">
import Loader from '@/components/ui/Loader.vue';
import { useAppStore } from '@/store/app';
import ScrollToTop from '@core/components/ScrollToTop.vue';
import initCore from '@core/initCore';
import { initConfigStore, useConfigStore } from '@core/stores/config';
import { hexToRgb } from '@core/utils/colorConverter';

import EditCategory from '@/components/Forms/FlightsCategories/Edit.vue';
import EditDestination from '@/components/Forms/HootDestinations/Edit.vue';
import { useTheme } from 'vuetify';

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
initCore()
initConfigStore()

const appStore = useAppStore()
const configStore = useConfigStore()
</script>

<template>
  <VLocaleProvider :rtl="configStore.isAppRTL">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <loader :isLoading="appStore.isLoading" />
      <RouterView />

      <ScrollToTop />

      <EditDestination />
      <EditCategory />
    </VApp>
  </VLocaleProvider>
</template>
