<script lang="ts" setup>
import { useFlightCategoriesStore } from '@/store/flightCategories';
import type { FlightCategory } from '@/types/flightCategories';
type Model = {
  name: string
  description: string
  imageUrl: File | null
}

const model = ref<Model>({
  name: '',
  description: '',
  imageUrl: null,
})


const isLoading = ref(false)
const flightCategoriesStore = useFlightCategoriesStore()
const categories = computed(() => flightCategoriesStore.categories)
const isDialogVisible = computed(() => flightCategoriesStore.forms.edit.isDialogVisible)
const categoryId = computed(() => flightCategoriesStore.forms.edit.id)

const onClose = () => {
  flightCategoriesStore.forms.edit.isDialogVisible = false
}

watch(() => categoryId.value, async (newVal) => {
  if (newVal) {
    isLoading.value = true
    const fetchedCategory = await flightCategoriesStore.getCategoryById(newVal)

    const newModel = {
      ...fetchedCategory,
      imageUrl: null,
    }

    // if (fetchedCategory.imageUrl) {
    //   newModel.imageUrl = await createFileFromUrl(fetchedCategory.imageUrl)
    // }

    model.value = newModel

    isLoading.value = false
  }
})

async function handleEditCategory() {
  const res = await flightCategoriesStore.updateCategory(model.value as unknown as FlightCategory)

  flightCategoriesStore.forms.edit.isDialogVisible = false
}
</script>

<template>
  <VDialog v-model="isDialogVisible" max-width="600" :loading="isLoading">
    <!-- Dialog close btn -->
    <DialogCloseBtn @click="onClose" />

    <!-- Dialog Content -->
    <VCard title="Flight Category">
      <VCardText>
        <VForm @submit.prevent="handleEditCategory">
          <VRow>
            <VCol cols="12">
              <VFileInput v-model="model.imageUrl" label="Image" />
            </VCol>

            <VCol cols="12">
              <VRow no-gutters>
                <!-- 👉 Country -->
                <VCol cols="12" md="3" class="d-flex align-items-center">
                  <label class="v-label text-body-2 text-high-emphasis" for="country">Country</label>
                </VCol>

                <VCol cols="12" md="9">
                  <AppTextField id="name" v-model="model.name" placeholder="Enter your name" persistent-placeholder />
                </VCol>
              </VRow>
            </VCol>

            <VCol cols="12">
              <VRow no-gutters>
                <!-- 👉 Description -->
                <VCol cols="12" md="3" class="d-flex align-items-center">
                  <label class="v-label text-body-2 text-high-emphasis" for="description">Description</label>
                </VCol>

                <VCol cols="12" md="9">
                  <VTextarea id="description" v-model="model.description" placeholder="Enter description"
                    persistent-placeholder rows="4" />
                </VCol>
              </VRow>
            </VCol>
          </VRow>

          <div class="d-flex justify-end flex-wrap gap-3 mt-8">
            <VBtn variant="tonal" color="secondary" @click="onClose">
              Close
            </VBtn>
            <VBtn type="submit">
              Save
            </VBtn>
          </div>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style scoped>
.create-btn {
  align-self: flex-start;
}
</style>
