import { useAppStore } from '@/store/app';
import type { Destination, DestinationCode } from '@/types/hootDestinations';

type HootDestination = {
  country: string
  city: string
  destinationCode: string
  price: string
  description: string
  priceCurrency: string
  imageUrl: File | null
  categoriesIds: number[]
}

export const useHootDestinationsStore = defineStore('hootDestinations', {
  state: () => ({
    destinations: [] as Destination[],
    destinationCodes: [] as DestinationCode[],
    forms: {
      edit: {
        isDialogVisible: false,
        id: null as number | null,
      },
    },
  }),
  actions: {
    async getDestinationCodes() {
      try {
        const res = await $api('/ticket/destinations')

        if (res) {
          this.destinationCodes = res.destinations
        }

        return res
      } catch (error) {
        console.log({ error })
      }
    },

    async getHootDestinations() {
      const appStore = useAppStore()

      appStore.isLoading = true
      try {
        const res = await $api('/hot-dest')

        appStore.isLoading = false
        this.destinations = res.destinations;
        return res.destinations;
      } catch (error) {
        console.log({ error })
      } finally {
        appStore.isLoading = false
      }
    },

    async getHootDestinationById(id: number) {
      const appStore = useAppStore()

      appStore.isLoading = true
      try {
        const res = await $api(`/hot-dest/${id}`)

        return res
      } catch (error) {
        console.log({ error })
      } finally {
        appStore.isLoading = false
      }
    },

    async createHootDestination(params: HootDestination) {
      const appStore = useAppStore()

      appStore.isLoading = true
      try {
        const file = params.imageUrl
        const newParams = new FormData()
        Object.entries(params).forEach(([key, value]) => {
          if (key !== 'imageUrl' && value !== null) {
            newParams.append(key, value as string);
          }

          if (key === 'categoriesIds') {
            params.categoriesIds.forEach(id => {
              newParams.append('categoriesIds[]', id.toString());
            });
          }
        });
        if (file) {
          newParams.append('image', file);
        }

        const res = await $api('/hot-dest', {
          method: 'POST',
          body: newParams,
        })

        if (res) {
          await this.getHootDestinations()

          return res;
        }
      } catch (error) {
        console.log({ error })
      } finally {
        appStore.isLoading = false
      }
    },

    async updateHootDestination(params: HootDestination) {
      const appStore = useAppStore()

      appStore.isLoading = true
      try {
        const newParams = new FormData()
        Object.entries(params).forEach(([key, value]) => {
          if (key !== 'imageUrl' && value !== null) {
            newParams.append(key, value as string);
          }
          if (key === 'categoriesIds') {
            params.categoriesIds.forEach(id => {
              newParams.append('categoriesIds[]', id.toString());
            });
          }
        });

        if (params.imageUrl) {
          newParams.append('image', params.imageUrl);
        }
        const res = await $api(`/hot-dest/${this.forms.edit.id}`, {
          method: 'PATCH',
          body: newParams,
        })

        if (res) {
          await this.getHootDestinations()
        }
      } catch (error) {
        console.log({ error })
      } finally {
        appStore.isLoading = false
      }
    },

    async deleteHootDestination(id: number) {
      const appStore = useAppStore()

      appStore.isLoading = true
      try {
        const res = await $api(`/hot-dest/${id}`, {
          method: 'DELETE',
        })

        if (res) {
          await this.getHootDestinations()
        }
      } catch (error) {
        console.log({ error })
      } finally {
        appStore.isLoading = false
      }
    },
  },
})
