import { defineStore } from 'pinia'

const INITIAL_APP_STATE = {
  isLoading: false
}

export const useAppStore = defineStore('app', {
  state: () => (INITIAL_APP_STATE),
  actions: {

  }
})
