import { useAppStore } from '@/store/app';
import type { FlightCategory } from '@/types/flightCategories';

export const useFlightCategoriesStore = defineStore('flightCategories', {
  state: () => ({
    categories: [] as FlightCategory[],
    forms: {
      edit: {
        isDialogVisible: false,
        id: null as number | null,
      },
    },
  }),
  actions: {
    async getCategories() {
      const appStore = useAppStore()

      appStore.isLoading = true
      try {
        const res = await $api('/hot-dest/category?destinations=false')

        appStore.isLoading = false
        this.categories = res.categories;
        return res.categories;
      } catch (error) {
        console.log({ error })
      } finally {
        appStore.isLoading = false
      }
    },

    async getCategoryById(id: number) {
      const appStore = useAppStore()

      appStore.isLoading = true
      try {
        const res = await $api(`/hot-dest/category/${id}?destinations=false`)

        return res
      } catch (error) {
        console.log({ error })
      } finally {
        appStore.isLoading = false
      }
    },

    async createCategory(params: FlightCategory) {
      const appStore = useAppStore()

      appStore.isLoading = true
      try {
        const file = params.imageUrl
        const formData = new FormData()
        Object.entries(params).forEach(([key, value]) => {
          if (key !== 'imageUrl' && value !== null) {
            formData.append(key, value as string);
          }
        });
        if (file) {
          formData.append('image', file);
        }

        const res = await $api('/hot-dest/category', {
          method: 'POST',
          body: formData,
        })

        if (res) {
          await this.getCategories()

          return res;
        }
      } catch (error) {
        console.log({ error })
      } finally {
        appStore.isLoading = false
      }
    },

    async updateCategory(params: FlightCategory) {
      const appStore = useAppStore()

      appStore.isLoading = true
      try {
        const res = await $api(`/hot-dest/category/${this.forms.edit.id}`, {
          method: 'PATCH',
          body: params,
        })

        if (res) {
          await this.getCategories()
        }
      } catch (error) {
        console.log({ error })
      } finally {
        appStore.isLoading = false
      }
    },

    async deleteCategory(id: number) {
      const appStore = useAppStore()

      appStore.isLoading = true
      try {
        const res = await $api(`/hot-dest/category/${id}`, {
          method: 'DELETE',
        })

        if (res) {
          await this.getCategories()
        }
      } catch (error) {
        console.log({ error })
      } finally {
        appStore.isLoading = false
      }
    },
  },
})
