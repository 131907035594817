<script setup lang="ts">
import { onBeforeUnmount, onMounted, watchEffect } from 'vue';

interface Props {
  isLoading: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isLoading: false
})

onMounted(() => {
  watchEffect(() => {
    if (props.isLoading) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = ''
    }
  })
})

onBeforeUnmount(() => {
  document.documentElement.style.overflow = ''
})
</script>

<template>
  <div v-if="isLoading" class="overlay">
    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<style scoped>
.overlay {
  position: fixed;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 50%);
  block-size: 100%;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
}
</style>
